






















































































































































































































































































import {
  API_ACTIVITY,
  API_USER_LIST,
  API_UPLOAD_ATTACHMENT,
} from "@/configs/Apis";
import {
  MSG_ADD_SUCCESS,
  MSG_INVALID_PAGE,
  MSG_MODIFY_SUCCESS,
} from "@/configs/Consts";
import formatters from "@/utils/ColumnFormater";
import { hasPermission, showError, showSuccess, showSuccessLong, showWarning } from "@/utils/Common";
import { Fetcher as Ajax } from "@/utils/Request";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class PatentActivityForm extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  patentActivityItem!: any;

  fileList = [];

  users: Array<any> = [];
  loadingUser = false;

  queryType(query: string, cb: any) {
    const allData = this.$store.getters["enums/getEnumsByKey"]("ActivityType");
    const datas: Array<any> = [];
    allData.forEach((data: any) => {
      datas.push({ value: data.label });
    });
    const results = query ? datas.filter((data: any) => {
      return data.value.toLowerCase().indexOf(query.toLowerCase()) === 0;
    }) : datas;
    cb(results);
  }

  querySubject(query: string, cb: any) {
    const allData = this.$store.getters["enums/getEnumsByKey"]("ActivitySubject");
    const datas: Array<any> = [];
    allData.forEach((data: any) => {
      datas.push({ value: data.label });
    });
    const results = query ? datas.filter((data: any) => {
      return data.value.toLowerCase().indexOf(query.toLowerCase()) === 0;
    }) : datas;
    cb(results);
  }

  mounted() {
    this.fileList = JSON.parse(
      JSON.stringify(this.patentActivityItem.attachmentList || [])
    );
    this.users = [];
    const currUserId = (this.$store.getters["user/user"] || {}).id;
    Ajax.queryData(API_USER_LIST, { keyword: "", userType: "INNER" }, "POST")
      .then((rs) => {
        const users = rs.data || [];
        let currUser: any = null;
        users.forEach((user: any) => {
          if (user.id === currUserId) {
            currUser = user;
          } else {
            this.users.push(user);
          }
        });
        if (currUser) {
          currUser.name = `${currUser.name}[自己]`;
          this.users.unshift(currUser);
        }
      })
      .catch(() => "");
  }

  get colFormatters() {
    return formatters;
  }

  hasPerm(checkedPerm: string) {
    return hasPermission(this.$store.getters["user/user"] || {}, checkedPerm);
  }

  queryUser(query: string) {
    if (query !== "") {
      this.loadingUser = true;
      setTimeout(() => {
        this.loadingUser = false;
        Ajax.queryData(API_USER_LIST, { keyword: query, userType: "INNER" }, "POST")
          .then((rs) => {
            this.users = rs.data;
          })
          .catch(() => "");
      }, 200);
    } else {
      this.users = [];
    }
  }

  get uploadAction() {
    return Ajax.formatApiUrl(API_UPLOAD_ATTACHMENT, {});
  }

  handlePreview(file: any) {
    const url = file.url || file.response.location;
    if (url) {
      window.open(url);
    }
  }

  handleUploadSuccess(rs: any, file: any) {
    const fileList = JSON.parse(
      JSON.stringify(this.patentActivityItem.attachmentList || [])
    );
    fileList.push({
      id: 0,
      name: file.raw.name,
      url: file.response.location,
      fileSize: file.size || file.fileSize || 0,
    });
    this.patentActivityItem.attachmentList = JSON.parse(
      JSON.stringify(fileList)
    );
    showSuccess("上传成功，请点击下方【保存】按钮进行保存");

    if (rs.parseResult) {
      showSuccessLong(`系统识别到您上传的文件为：${rs.parseResult.name}<br>已更新专利: #<font color=red>${rs.parseResult.patent.appNo}</font># <font color=red>${rs.parseResult.patent.title}</font> 的如下字段:<br><font color=red>授权日</font> 更新为: <font color=red>${rs.parseResult.patent.authDate}</font><br><font color=red>授权号</font> 更新为: <font color=red>${rs.parseResult.patent.authNo}</font><br><font color=red>证书号</font> 更新为: <font color=red>${rs.parseResult.patent.certificateNo}</font>`);
    }
  }

  handleRemove(file: any, fileList: any) {
    const newFileList = fileList.map((fn: any) => {
      return {
        id: fn.id || 0,
        name: fn.name || fn.raw.name,
        url: fn.url || fn.response.location,
        fileSize: file.size || file.fileSize || 0,
      };
    });
    this.patentActivityItem.attachmentList = JSON.parse(
      JSON.stringify(newFileList)
    );
  }

  isEdit() {
    return this.patentActivityItem && this.patentActivityItem.id;
  }

  savePatentActivity() {
    const form = this.$refs.patentActivityForm as any;
    form.validate((isValid: boolean) => {
      if (!isValid) {
        showWarning(MSG_INVALID_PAGE);
        return;
      }
      const patentData = JSON.parse(JSON.stringify(this.patentActivityItem));
      const isEdit = this.isEdit();
      Ajax.saveData(
        isEdit ? `${API_ACTIVITY}/${this.patentActivityItem.id}` : API_ACTIVITY,
        patentData,
        isEdit ? "PUT" : "POST"
      )
        .then(() => {
          this.$emit("update:patentActivityItem", {});
          showSuccess(isEdit ? MSG_MODIFY_SUCCESS : MSG_ADD_SUCCESS);
          this.$emit("data-success");

          // reload enums
          this.$store.dispatch("enums/getEnums");
        })
        .catch(({ message }) => showError(message));
    });
  }
}
