
































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {
  API_ACTIVITY,
  API_PATENT,
  API_PATENT_EXTRA_FEE,
  API_PATENT_FILE,
  API_PATENT_LEGAL_STATUS,
  API_PATENT_USER,
} from "@/configs/Apis";
import formatters from "@/utils/ColumnFormater";
import {
  hasPermission,
  isEmptyOrWhiteSpace,
  showConfirm,
  showError,
  showSuccess,
  showWarning,
} from "@/utils/Common";
import { Fetcher as Ajax } from "@/utils/Request";
import PatentActivityForm from "@/views/patent/subviews/PatentActivityForm.vue";
import PatentExtraFeeForm from "@/views/patent/subviews/PatentExtraFeeForm.vue";
import PatentFileForm from "@/views/patent/subviews/PatentFileForm.vue";
import PatentForm from "@/views/patent/subviews/PatentForm.vue";
import PatentUserSelector from "@/views/patent/subviews/PatentUserSelector.vue";
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
@Component({
  components: {
    PatentForm,
    PatentActivityForm,
    PatentUserSelector,
    PatentExtraFeeForm,
    PatentFileForm,
  },
})
export default class PatentDetail extends Vue {
  @Prop({ type: String, default: () => "{}" })
  data!: string;
  @Prop({ type: Boolean, default: () => false })
  showGoNext!: boolean;
  @Prop({ type: Boolean, default: () => false })
  showGoPrev!: boolean;
  @Inject()
  getModuleName!: () => string;
  patentDetail: any = { patentFeeList: [], patentPaidFeeList: [], patentLateFeeList: [] };
  addPatentFormDialogVisible = false;
  patentItem: any = { id: 0, text: {} };
  collapseInfo = true;
  patentFilter: any = {};
  selectUserDialogVisible = false;
  patentExtraFeeFormDialogVisible = false;
  patentExtraFeeItem: any = { id: 0 };
  patentFileFormDialogVisible = false;
  patentFileItem: any = { id: 0 };
  patentActivityFormDialogVisible = false;
  patentActivityItem: any = { id: 0 };
  patentLegalStatusList: Array<any> = [];

  initFeeItems: Array<any> = ["fee", "lateFee"];

  currentFeePage = 1;
  feePageSize = 5;

  currentPaidFeePage = 1;
  paidFeePageSize = 5;

  currentLateFeePage = 1;
  lateFeePageSize = 5;

  handleCurrentFeeChange(page: number) {
    this.currentFeePage = page;
  }
  handleCurrentPaidFeeChange(page: number) {
    this.currentPaidFeePage = page;
  }
  handleCurrentLateFeeChange(page: number) {
    this.currentLateFeePage = page;
  }

  mounted() {
    this.patentDetail = JSON.parse(this.data);
    this.initFileList();
    this.patentFilter = { selectedIds: [this.patentDetail.id] };
    this.$nextTick(() => this.updateTableLayout());
  }
  get isPct() {
    return this.patentDetail.appType === "PCT";
  }
  createdByMe(createdUserId: number) {
    const user = this.$store.getters["user/user"] || {};
    return createdUserId === user.id;
  }
  hasPerm(checkedPerm: string) {
    return hasPermission(this.$store.getters["user/user"] || {}, checkedPerm);
  }
  // @Watch("patentDetail.appNo")
  onAppNoChange() {
    Ajax.queryData(
      `${API_PATENT_LEGAL_STATUS}?appNo=${this.patentDetail.appNo}`,
      {},
      "GET"
    )
      .then((rs: any) => {
        this.patentLegalStatusList = rs || {};
      })
      .catch(({ message }) => showError(message));
  }
  goNext() {
    this.$emit("next-item");
  }
  goPrev() {
    this.$emit("prev-item");
  }
  formatText(str: string) {
    if (!str) {
      return "暂未填写";
    }
    return str.replace(/[\r|\n]/g, "<br>");
  }

  formatNoDeadlineClosed(closed: number) {
    return closed ? "已完成" : "未完成";
  }

  formatDeadlineClosed(deadline: string, closed: number) {
    if (!deadline) {
      return "--";
    }
    return closed ? "已完成" : "未完成";
  }
  reloadPatentDetail() {
    Ajax.queryData(`${API_PATENT}/${this.patentDetail.id}`, {}, "GET")
      .then((rs: any) => {
        this.patentDetail = rs || {};
        this.patentFilter.selectedIds = [this.patentDetail.id];
        this.initFileList();
        this.$nextTick(() => this.updateTableLayout());
      })
      .catch(({ message }) => showError(message));
  }
  @Watch("data")
  onDataChange() {
    this.patentDetail = JSON.parse(this.data);
    this.patentFilter.selectedIds = [this.patentDetail.id];
    this.initFileList();
    this.$nextTick(() => this.updateTableLayout());
  }
  initFileList() {
    this.patentDetail.patentFileList = this.patentDetail.patentFileList || [];
    this.patentDetail.patentFileList.forEach((patentFile: any) => {
      patentFile.fileList = JSON.parse(patentFile.files || "[]");
      patentFile.fileCount = patentFile.fileList.length;
    });
  }
  get colFormatters() {
    return formatters;
  }
  editPatent() {
    this.patentItem = JSON.parse(JSON.stringify(this.patentDetail));
    this.addPatentFormDialogVisible = true;
  }
  saveBiboSuccess() {
    this.addPatentFormDialogVisible = false;
    this.reloadPatentDetail();
    this.$emit("detail-changed", "bibo");
  }
  addPatentUser() {
    this.selectUserDialogVisible = true;
    this.reloadPatentDetail();
  }
  onPatentUserSaveSuccess() {
    this.selectUserDialogVisible = false;
    this.reloadPatentDetail();
    this.$emit("detail-changed", "user");
  }
  removePatentUser(patentUser: any) {
    showConfirm(`是否确认移除${patentUser.name}？`).then(() => {
      Ajax.saveData(
        `${API_PATENT_USER}/${this.patentDetail.id}/${patentUser.id}`,
        {},
        "DELETE"
      )
        .then(() => {
          showSuccess("移除成功");
          this.reloadPatentDetail();
          this.$emit("detail-changed", "user");
        })
        .catch(({ message }) => showError(message));
    });
  }
  addPatentExtraFee() {
    this.patentExtraFeeItem = { id: 0, patentId: this.patentDetail.id };
    this.patentExtraFeeFormDialogVisible = true;
  }
  editPatentExtraFee(extraFee: any) {
    this.patentExtraFeeItem = JSON.parse(JSON.stringify(extraFee));
    this.patentExtraFeeFormDialogVisible = true;
  }
  saveExtraFeeSuccess() {
    this.patentExtraFeeFormDialogVisible = false;
    this.reloadPatentDetail();
    this.$emit("detail-changed", "fee");
  }
  removePatentExtraFee(extraFee: any) {
    showConfirm(`是否确认移除${extraFee.feeCategory}？`).then(() => {
      Ajax.saveData(`${API_PATENT_EXTRA_FEE}/${extraFee.id}`, {}, "DELETE")
        .then(() => {
          showSuccess("移除成功");
          this.reloadPatentDetail();
          this.$emit("detail-changed", "fee");
        })
        .catch(({ message }) => showError(message));
    });
  }
  addPatentFile() {
    this.patentFileItem = { id: 0, patentId: this.patentDetail.id };
    this.patentFileFormDialogVisible = true;
  }
  editPatentFile(file: any) {
    this.patentFileItem = JSON.parse(JSON.stringify(file));
    this.patentFileFormDialogVisible = true;
  }
  saveFileSuccess() {
    this.patentFileFormDialogVisible = false;
    this.reloadPatentDetail();
    this.$emit("detail-changed", "file");
  }
  removePatentFile(file: any) {
    showConfirm(`是否确认移除${file.name}？`).then(() => {
      Ajax.saveData(`${API_PATENT_FILE}/${file.id}`, {}, "DELETE")
        .then(() => {
          showSuccess("移除成功");
          this.reloadPatentDetail();
          this.$emit("detail-changed", "file");
        })
        .catch(({ message }) => showError(message));
    });
  }
  downloadPatentFile(file: any) {
    if (isEmptyOrWhiteSpace(file.url)) {
      showWarning("下载地址错误");
      return;
    }
    window.open(file.url);
  }
  addActivity() {
    this.patentActivityItem = {
      id: 0,
      boName: "Patent",
      dataId: this.patentDetail.id,
    };
    this.patentActivityFormDialogVisible = true;
  }
  editActivity(file: any) {
    this.patentActivityItem = JSON.parse(JSON.stringify(file));
    this.patentActivityFormDialogVisible = true;
  }
  saveActivitySuccess() {
    this.patentActivityFormDialogVisible = false;
    this.reloadPatentDetail();
    this.$emit("detail-changed", "activity");
  }
  removeActivity(activity: any) {
    showConfirm(`是否确认移除${activity.subject}？`).then(() => {
      Ajax.saveData(`${API_ACTIVITY}`, { selectedIds: [activity.id] }, "DELETE")
        .then(() => {
          showSuccess("移除成功");
          this.reloadPatentDetail();
          this.$emit("detail-changed", "activity");
        })
        .catch(({ message }) => showError(message));
    });
  }
  openCnipaLink() {
    if (isEmptyOrWhiteSpace(this.patentDetail.pubNo)) {
      showWarning("目前只支持查看已公开专利");
      return;
    }
    window.open(
      "http://epub.cnipa.gov.cn/tdcdesc.action?strWhere=CN" +
      this.patentDetail.pubNo
    );
  }
  openEspacenetLink() {
    if (isEmptyOrWhiteSpace(this.patentDetail.pubNo)) {
      showWarning("目前只支持查看已公开专利");
      return;
    }
    window.open(
      "https://worldwide.espacenet.com/publicationDetails/biblio?CC=CN&NR=" +
      this.patentDetail.pubNo +
      "&FT=D&locale=cn_EP"
    );
  }
  onTabClick() {
    this.$nextTick(() => this.updateTableLayout());
  }
  updateTableLayout() {
    const refs = this.$refs as any;
    refs.gridPatentApplicant?.doLayout();
    refs.gridPatentInventor?.doLayout();
    refs.gridPatentPriority?.doLayout();
    refs.gridPatentUser?.doLayout();
    refs.gridPatentLog?.doLayout();
    refs.gridActivity?.doLayout();
    refs.gridPatentFile?.doLayout();
    refs.gridPatentExtraFee?.doLayout();
  }
}
